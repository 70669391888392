import Vue from 'vue'
import Router from 'vue-router'

import Home from './views/Home'

Vue.use(Router)

export const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '*',
      redirect: 'home'
    }
  ]
})

export function hasMeta (route, key) {
  return route.matched.some((record) => !!record.meta[key])
}
