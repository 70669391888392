<template lang="pug">
  .bg-lightblue
    .flex(:class="`${styles.mainContainer} pt-28 sm_pt-32 md_pt-40 pb-16 sm_pb-24 md_pb-28`")
      .flex-1
        .text-title.leading-relaxed.font-serif-bold.text-darkblue.text-center.md_text-left
          div Nous développons des
          div.whitespace-normal.md_whitespace-nowrap applications pour simplifier
          div.flex.flex-row.items-center.justify-center.md_justify-start.flex-wrap.md_flex-nowrap
            div.w-8 {{currentTitle[0]}}
            .flex.bg-fadedblue.ml-1.mr-2.lg_mx-4.rounded-lg.py-0.h-10.sm_h-12.md_h-14.my-1.md_my-0.md_-mt-1.items-center.pt-1.justify-center.w-28.sm_w-36.lg_w-44
              transition(name="fade" mode="out-in")
                span.text-blue(v-if="showTitle") &nbsp{{currentTitle[1]}}&nbsp
            span.whitespace-nowrap de données
        p.text-base.lg_text-lg.font-sans-regular.text-grey.mt-5.mb-6.mx-auto.md_mx-0.sm_max-w-lg.leading-loose.text-center.md_text-left Granite est une agence digitale spécialisée dans la conception et le développement d'outils de gestion & d'analyse de données entièrement sur-mesure
        .flex.justify-center.md_justify-start
          CustomButton(
            label="Parlez-nous de votre projet"
            icon="✉️"
            arrow="right"
            @onClick="scrollToContact"
          )
      .flex-1.hidden.md_block.relative
        img.absolute(
          style="right: 0; top: 0; bottom: 0; margin-top: auto; margin-bottom: auto"
          :class="'md_w-11/12'"
          src="@/assets/images/illustrations/header-illustration.svg"
        )
</template>

<script>
import CustomButton from '@/components/CustomButton'
import styles from '@/assets/styles'

export default {
  name: 'Header',
  components: {
    CustomButton
  },
  data () {
    return {
      currentTitle: ['la', 'collecte'],
      showTitle: true,
      styles
    }
  },
  mounted () {
    setTimeout(() => {
      this.updateTitle()
      setInterval(this.updateTitle, 3000)
    }, 2000)
  },
  methods: {
    updateTitle () {
      const titles = [
        ['la', 'collecte'],
        ['l\'', 'analyse'],
        ['le', 'partage']
      ]
      let currentIndex = titles.findIndex(e => e[1] === this.currentTitle[1])
      if (currentIndex !== -1) {
        this.showTitle = false
      }
      let newIndex = currentIndex === 2 ? 0 : currentIndex + 1
      setTimeout(() => {
        this.currentTitle = titles[newIndex]
        this.showTitle = true
      }, 1000)
    },
    scrollToContact () {
      var element = document.getElementById('contact');
      var top = element.offsetTop;
      window.scrollTo(0, top - 100);
    }
  }
}
</script>
