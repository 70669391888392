<template lang="pug">
  #app
    router-view
    Footer
</template>

<script>
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Footer
  }
}
</script>
