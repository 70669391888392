import axios from 'axios'

export default {
  sendContactEmail (payload) {
    return axios.post('/contact-email', payload)
      .then(response => {
        return response.data
      })
  }
}
