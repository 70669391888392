<template lang="pug">
  div(:class="styles.mainContainer")
    .flex.flex-row.justify-center.items-center.py-6.mb-12.flex-wrap
      .flex.items-center.justify-center.px-1.py-4(:class="'w-1/4 lg_flex-1'")
        img.max-h-4.md_max-h-5(src="@/assets/images/clients/air-liquide.png")
      .flex.items-center.justify-center.px-2.sm_px-4.py-4.relative(:class="'w-1/4 lg_flex-1'")
        img.max-h-4.md_max-h-5(src="@/assets/images/clients/okamac.png")
      .flex.items-center.justify-center.px-2.sm_px-4.py-4.relative(:class="'w-1/4 lg_flex-1'")
        img.max-h-4.md_max-h-5(src="@/assets/images/clients/euromaster.png")
      .flex.items-center.justify-center.px-2.sm_px-4.py-4.relative(:class="'w-1/4 lg_flex-1'")
        img.max-h-4.md_max-h-5(src="@/assets/images/clients/nexans.png")
      .flex.items-center.justify-center.px-2.sm_px-4.py-4.relative(:class="'w-1/4 lg_flex-1'")
        img.max-h-4.md_max-h-5(src="@/assets/images/clients/hashtag-you.png")
      .flex.items-center.justify-center.px-2.sm_px-4.py-4.relative(:class="'w-1/4 lg_flex-1'")
        img.max-h-4.md_max-h-5(src="@/assets/images/clients/valeo.png")
      .flex.items-center.justify-center.px-2.sm_px-4.py-4.relative(:class="'w-1/4 lg_flex-1'")
        img.max-h-3.md_max-h-4(src="@/assets/images/clients/deloitte.png")
</template>

<script>
import styles from '@/assets/styles'

export default {
  name: 'Clients',
  data () {
    return {
      styles
    }
  }
}
</script>
