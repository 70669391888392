<template lang="pug">
  #contact(:class="`${styles.mainContainer} ${styles.block}`")
    .bg-lightblue.p-8.max-w-3xl.m-auto.rounded-lg
      p(:class="`${styles.blockTitle} mb-4`") Contactez-nous
      p(:class="'font-sans-regular text-base md_text-lg text-darkblue text-center mb-6 max-w-3xl leading-loose m-auto'") Vous pouvez nous écrire à&nbsp
        a(href="mailto:hello@grnt.fr")
          span.text-blue.font-sans-semibold hello@grnt.fr
        span &nbspou remplir le formulaire ci-dessous:
      form(@submit.prevent="sendMail")
        .flex.flex-wrap(class="-mx-2")
          div(class="w-full md_w-1/2 px-2 mb-4")
            label.block.text-darkblue.text-sm.mb-2.font-sans-semibold Nom *
            input(
              :class="styles.input"
              type="text"
              placeholder="Nom"
              v-model="mailInfo.name"
              required
            )
          div(class="w-full md_w-1/2 px-2 mb-4")
            label.block.text-darkblue.text-sm.mb-2.font-sans-semibold Société *
            input(
              :class="styles.input"
              type="text"
              placeholder="Société"
              v-model="mailInfo.company"
              required
            )
        .flex.flex-wrap(class="-mx-2")
          div(class="w-full md_w-1/2 px-2 mb-4")
            label.block.text-darkblue.text-sm.mb-2.font-sans-semibold Rôle
            input(
              :class="styles.input"
              type="text"
              placeholder="Rôle"
              v-model="mailInfo.role"
            )
          div(class="w-full md_w-1/2 px-2 mb-4")
            label.block.text-darkblue.text-sm.mb-2.font-sans-semibold Email *
            input(
              :class="styles.input"
              type="text"
              placeholder="Email"
              v-model="mailInfo.email"
              required
            )
        .flex(class="-mx-2")
          div(class="w-full px-2 mb-4")
            label.block.text-darkblue.text-sm.mb-2.font-sans-semibold Message *
            textarea(
              :class="styles.input"
              style="min-height: 140px"
              type="text"
              placeholder="Message"
              rows="5"
              v-model="mailInfo.message"
              required
            )
        div.flex.justify-end(v-if="!mailSending && !mailSent && !mailError")
          CustomButton(
            label="Envoyer"
            icon="✉️"
            arrow="right"
            type="submit"
          )
        p.font-sans-regular.text-base.md_text-lg.text-darkblue(v-else-if="mailSending && !mailSent && !mailError") En cours d'envoi...
        p.font-sans-regular.text-base.md_text-lg.text-darkblue(v-else-if="mailSent") Message envoyé avec succès &nbsp🎉
        p.font-sans-regular.text-base.md_text-lg.text-darkblue(v-else) Erreur d'envoi, désolé &nbsp😨 ! Vous pouvez nous écrire à hello@grnt.fr
</template>

<script>
import styles from '@/assets/styles'

import CustomButton from '@/components/CustomButton'

import MailerService from '@/services/MailerService'

export default {
  name: 'Contact',
  components: {
    CustomButton
  },
  data () {
    return {
      mailError: false,
      mailInfo: {
        name: '',
        company: '',
        role: '',
        email: '',
        message: ''
      },
      mailSending: false,
      mailSent: false,
      styles
    }
  },
  methods: {
    sendMail () {
      this.mailSending = true
      MailerService.sendContactEmail(this.mailInfo).then(() => {
        this.mailSent = true
        this.mailError = false
      })
      .catch(() => {
        this.mailSent = false
        this.mailError = true
      })
    }
  }
}
</script>
