<template lang="pug">
  div
    Navbar
    Header
    Clients
    Expertise
    Work
    Testimonials
    Founders
    Contact
</template>

<script>

import Clients from '@/components/Clients'
import Contact from '@/components/Contact'
import CustomButton from '@/components/CustomButton'
import Expertise from '@/components/Expertise'
import Founders from '@/components/Founders'
import Header from '@/components/Header'
import Navbar from '@/components/Navbar'
import Testimonials from '@/components/Testimonials'
import Work from '@/components/Work'

export default {
  name: 'Home',
  components: {
    Clients,
    Contact,
    CustomButton,
    Expertise,
    Founders,
    Header,
    Navbar,
    Testimonials,
    Work
  }
}
</script>
