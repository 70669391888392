<template lang="pug">
  div(:class="`${styles.mainContainer} ${styles.block}`")
    p(:class="styles.blockTitle") Notre
      span.text-blue &nbspexpertise
    p(:class="styles.blockSubtitle") Nous aidons nos clients à poser les
      span.font-sans-semibold &nbspfondations technologiques nécessaires&nbsp
      span pour disposer d’indicateurs permettant de
      span.font-sans-semibold &nbsppiloter&nbsp
      span l’activité au quotidien,
      span.font-sans-semibold &nbspfaciliter&nbsp
      span la prise de décision et
      span.font-sans-semibold &nbspanticiper&nbsp
      span ses évolutions
    .flex.flex-row.flex-wrap.md_flex-nowrap.max-w-5xl.m-auto.justify-center
      .w-full.max-w-sm.lg_flex-1.mx-0.md_mr-4.rounded-2xl.custom-shadow-large.px-8.py-12.mb-6.md_mb-0(
        v-for="(expertise, index) of expertises"
        :key="index"
      )
        img.mx-auto.mb-12.h-16.md_h-24(:src="expertise.picture")
        p.text-xl.md_text-2xl.font-sans-semibold.mb-8(v-html="expertise.title")
        p.text-base.md_text-lg.font-sans-regular.text-grey(v-html="expertise.description")
</template>

<script>
import styles from '@/assets/styles'

export default {
  name: 'Expertise',
  data () {
    return {
      expertises: [
        {
          picture: require('@/assets/images/illustrations/what-we-do-1.svg'),
          title: 'La<span class="text-blue"> collecte </span>de données',
          description: 'Nous nous assurons que les données de nos clients sont nettoyées, enrichies et prêtes pour exploitation et analyse'
        },
        {
          picture: require('@/assets/images/illustrations/what-we-do-2.svg'),
          title: 'L\'<span class="text-blue">analyse </span>de données',
          description: 'Nous identifions et adaptons les algorithmes de data science les plus pertinents pour répondre aux besoins des équipes'
        },
        {
          picture: require('@/assets/images/illustrations/what-we-do-3.svg'),
          title: 'Le<span class="text-blue"> partage </span>de données',
          description: 'Nous construisons des interfaces web & mobile 100% sur-mesure pour proposer l’environnement le plus intuitif possible aux utilisateurs'
        }
      ],
      styles
    }
  }
}
</script>
