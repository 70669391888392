<template lang="pug">
  div(:class="`${styles.mainContainer} ${styles.block}`")
    p(:class="styles.blockTitle") Nos
      span.text-blue &nbspréalisations
    p(:class="styles.blockSubtitle") Toutes nos missions sont réalisées en
      span.font-sans-semibold &nbspco-construction&nbsp
      span avec nos clients pour comprendre de manière précise les besoins et&nbsp
      span.font-sans-semibold favoriser l'adoption&nbsp
      span des applications développées
    .flex.flex-row.flex-wrap
      .mb-8.lg_mb-0(class="w-full lg_w-3/5")
        .border-8.border-lightblue.rounded-2xl.rounded-b-none.custom-shadow.w-full.relative(
          style="border-top-width: 40px; padding-top: 60.3333%"
        )
          transition(name="fade" mode="in-out")
            img.w-full.absolute.top-0.left-0(
              v-if="showPicture"
              :src="require(`@/assets/images/missions/${works[currentWorkIndex].pictures[currentPictureIndex]}`)"
            )
      .flex.flex-row.items-start(class="w-full lg_w-2/5")
        CustomButton.mr-4.lg_hidden(
          arrow="left"
          @onClick="previousWork"
        )
        div.pl_0.lg_pl-12
          p.text-xl.md_text-2xl.font-sans-semibold.mb-8(v-html="works[currentWorkIndex].title")
          p.text-base.md_text-lg.font-sans-regular.text-grey.mb-12 {{works[currentWorkIndex].description}}
          .flex.flex-row.flex-wrap
            div(
              v-for="(language, index) of works[currentWorkIndex].languages"
              :key="index"
            )
              .w-12.h-12.rounded-2xl.mb-4.flex.justify-center.items-center.mr-4.relative(
                :style="{backgroundColor: getLanguage(language).backgroundColor}"
                @mouseover="showTooltip(`work_${currentWorkIndex}_lang_${index}`)"
                @mouseout="hideTooltip(`work_${currentWorkIndex}_lang_${index}`)"
              )
                img(
                  style="width: 22px"
                  :src="require(`@/assets/images/languages/${language}.png`)"
                )
                .absolute.bg-darkblue.rounded-lg.px-2.py-1.-top-10.whitespace-nowrap.text-center.opacity-0.text-white.text-sm.font-sans-regular(
                  :ref="`work_${currentWorkIndex}_lang_${index}`"
                  style="margin-left: auto; margin-right: auto; left: 0, right: 0"
                ) {{getLanguage(language).name}}
        CustomButton.ml-4.lg_hidden(
          arrow="right"
          @onClick="nextWork"
        )
    .flex-row.items-center.justify-center.hidden.lg_flex.pt-10.lg_pt-16
      CustomButton.mr-4(
        arrow="left"
        @onClick="previousWork"
      )
      .w-40.flex.flex-row.justify-between
        a(
          v-for="(work, index) of works"
          index="index"
          @click="setWorkIndex(index)"
        )
          .rounded-full.mx-2.h-3(
            style="transition: 200ms width linear, background-color 300ms linear 200ms"
            :class="[index === currentWorkIndex ? 'w-6 bg-blue' : 'w-3 bg-middleblue']"
          )
      CustomButton.ml-4(
        arrow="right"
        @onClick="nextWork"
      )
</template>

<script>
import CustomButton from '@/components/CustomButton'

import styles from '@/assets/styles'

export default {
  name: 'Work',
  components: {
    CustomButton
  },
  data () {
    return {
      currentPictureIndex: 0,
      currentWorkIndex: 0,
      works: [
        {
          title: '<span>Développement d’une plateforme de gestion de <span class="text-blue">campagnes marketing d\'influenceurs&nbsp</span>📱',
          description: 'Nous avons développé une plateforme sur-mesure permettant l’analyse automatique de données réseaux sociaux de milliers d\'influencers et la gestion de centaines de campagnes marketing hebdomadaires.',
          languages: ['vue', 'html', 'css', 'rails', 'python', 'google-cloud', 'mongodb'],
          pictures: [
            'hashtag/hashtag-home.png',
            'hashtag/hashtag-deal.png',
            'hashtag/hashtag-negotiation.png',
            'hashtag/hashtag-calendar.png',
            'hashtag/hashtag-dashboard.png',
            'hashtag/hashtag-mentions.png'
          ]
        },
        {
          title: '<span>Déploiement d’un <span class="text-blue">modèle prédictif de production</span> via une application web&nbsp🏭</span>',
          description: 'Nous avons conçu et développé un outil d’optimisation de plan de production pour Nexans. Le but de la mission était de rendre accessible un modèle de prédiction en usine via une application web & mobile.',
          languages: ['angular', 'html', 'css'],
          pictures: [
            'nexans/nexans-simulation.png',
            'nexans/nexans-production.png'
          ]
        },
        {
          title: '<span>Développement d\'un <span class="text-blue">ERP sur-mesure</span> de gestion d\'entrepot&nbsp📦</span>',
          description: 'Nous avons accompagné une société de reconditionnement d\'ordinateurs sur le développement d\'une plateforme de gestion de leur entrepot et atelier. L\'application déployée permet une gestion plus fine et automatisée du stock, une meilleure traçabilité des marchandises et la connexion en temps réel à une vingtaine de places de marché',
          languages: ['vue', 'html', 'css', 'rails', 'postgresql'],
          pictures: [
            'okamac/okamac-computers.png',
            'okamac/okamac-device.png',
            'okamac/okamac-preparation.png',
            'okamac/okamac-os.png'
          ]
        },
        {
          title: '<span>Développement front-end pour une startup digitale&nbsp📊</span>',
          description: 'Nous avons accompagné HypeSense, une startup spécialisée en analyse de données métier, dans la conception et le développement d’un outil de visualisation de données connecté à leur API interne et entièrement personnalisable.',
          languages: ['angular', 'html', 'css'],
          pictures: [
            'hypesense/hypesense-globaux.png',
            'hypesense/hypesense-regroupement.png',
            'hypesense/hypesense-processus.png'
          ]
        },
        {
          title: '<span>Développement d’un ERP pour une association&nbsp🧑‍🎓</span>',
          description: 'SJT est une association à but non lucratif dont la vocation est d’oeuvrer pour l’insertion sociale et professionnelle de jeunes en difficulté. Nous les avons accompagné dans la création d’un outil visant à digitaliser la gestion des dossiers de leurs bénéficiaires.',
          languages: ['vue', 'html', 'css', 'rails', 'mongodb'],
          pictures: [
            'sjt/sjt-liste.png',
            'sjt/sjt-fiche.png'
          ]
        }
      ],
      showPicture: true,
      styles
    }
  },
  mounted () {
    setTimeout(() => {
      this.changePicture()
      setInterval(this.changePicture, 4000)
    }, 2000)
  },
  methods: {
    changePicture () {
      this.showPicture = false
      if (this.currentPictureIndex === this.works[this.currentWorkIndex].pictures.length - 1) {
        this.currentPictureIndex = 0
        this.nextWork()
      } else {
        this.currentPictureIndex += 1
      }
      setTimeout(() => {
        this.showPicture = true
      }, 1000)
    },
    hideTooltip (ref) {
      const tooltip = this.$refs[ref]
      if (tooltip[0]) {
        tooltip[0].style.opacity = 0
      }
    },
    nextWork () {
      if (this.currentWorkIndex === this.works.length - 1) {
        this.setWorkIndex(0)
      } else {
        this.setWorkIndex(this.currentWorkIndex + 1)
      }
    },
    previousWork () {
      if (this.currentWorkIndex === 0) {
        this.setWorkIndex(this.works.length - 1)
      } else {
        this.setWorkIndex(this.currentWorkIndex - 1)
      }
    },
    getLanguage (language) {
      return {
        'angular': {
          name: 'Angular',
          backgroundColor: 'rgba(221, 3, 48, 0.20)'
        },
        'css': {
          name: 'CSS3',
          backgroundColor: 'rgba(38, 77, 228, 0.20)'
        },
        'google-cloud': {
          name: 'Google Cloud',
          backgroundColor: 'rgba(250, 187, 5, 0.20)'
        },
        'html': {
          name: 'HTML5',
          backgroundColor: 'rgba(228, 77, 38, 0.20)'
        },
        'mongodb': {
          name: 'MongoDB',
          backgroundColor: 'rgba(108, 172, 72, 0.20)'
        },
        'postgresql': {
          name: 'PostgreSQL',
          backgroundColor: 'rgba(51, 103, 145, 0.20)'
        },
        'python': {
          name: 'Python',
          backgroundColor: 'rgba(56, 116, 166, 0.20)'
        },
        'rails': {
          name: 'Ruby on Rails',
          backgroundColor: 'rgba(204, 0, 0, .20)'
        },
        'vue': {
          name: 'VueJs',
          backgroundColor: 'rgba(65, 183, 131, .20)'
        }
      }[language]
    },
    setWorkIndex (index) {
      this.currentPictureIndex = 0
      this.currentWorkIndex = index
    },
    showTooltip (ref) {
      const tooltip = this.$refs[ref]
      if (tooltip[0]) {
        tooltip[0].style.opacity = 1
      }
    }
  }
}
</script>
