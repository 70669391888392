<template lang="pug">
  button(
    type="button"
    :class="getClasses"
    :disabled="disabled"
    @click="onClick"
  )
    span.mr-4(v-if="icon") {{icon}}
    span.font-sans-semibold.whitespace-nowrap.text-base(
      v-if="label"
      :class="`text-${textColor}`"
    ) {{label}}
    .bg-middleblue.p-1.rounded-full(
      v-if="arrow"
      :class="{'ml-4': icon || label}"
    )
      arrow-left-icon(
        v-if="arrow === 'left'"
        size="15"
        stroke="#4946F2"
      )
      arrow-right-icon(
        v-else
        size="15"
        stroke="#4946F2"
      )
</template>

<script>
import { ArrowLeftIcon } from 'vue-feather-icons'
import { ArrowRightIcon } from 'vue-feather-icons'

export default {
  name: 'CustomButton',
  data () {
    return {
      paddings: {
        'md': 'p-4',
        'sm': 'px-4 p-2'
      }
    }
  },
  computed: {
    getClasses () {
      return [
        'rounded-lg flex flex-row items-center',
        this.shadow ? 'custom-shadow active_shadow-none' : '',
        this.paddings[this.size],
        `bg-${this.backgroundColor}`
      ].join(' ')
    }
  },
  components: {
    ArrowLeftIcon,
    ArrowRightIcon
  },
  props: {
    arrow: {
      type: String
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    label: {
      type: String
    },
    shadow: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    },
    textColor: {
      type: String,
      default: 'blue'
    }
  },
  methods: {
    onClick () {
      this.$emit('onClick')
    }
  }
}
</script>
