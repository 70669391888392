<template lang="pug">
  .w-full.z-20.fixed(:class="{'bg-white custom-shadow': scrolled}")
    .relative(:class="styles.mainContainer")
      svg.absolute.z-0.-left-20.sm_-left-6.lg_-left-5.-top-7.sm_-top-5(
        v-if="!scrolled"
        width="200"
        height="100"
        viewBox="0 0 252 123"
        fill="none"
      )
        path(
          d="M95.3174 109.024C70.7303 106.55 52.3357 120.074 32.3023 103.472C22.5333 95.3766 18.6242 88.1577 12.6747 76.1091C1.38351 53.243 4.16872 18.4459 0 -7.41722C0 -7.41722 250.632 -102.542 251.994 9.88241C252.23 29.3325 245.107 59.0567 245.107 59.0567C245.107 59.0567 239.298 96.3819 223 109.024C206.973 121.456 191.212 121.226 170 122.762C140.498 124.9 124.803 111.991 95.3174 109.024Z"
          fill="#FFF"
        )
      .flex.flex-row.justify-between.items-center.py-4.relative
        img.h-3.sm_h-4(src="@/assets/images/logo-dark.svg")
        .flex.flex-row.items-center
          CustomButton(
            label="Contactez-nous"
            :shadow="false"
            :icon="'✉️'"
            :size="'sm'"
            :backgroundColor="scrolled ? 'blue' : 'white'"
            :textColor="scrolled ? 'white' : 'blue'"
            @onClick="scrollToContact"
          )
</template>

<script>
import CustomButton from '@/components/CustomButton'
import styles from '@/assets/styles'

export default {
  name: 'Navbar',
  components: {
    CustomButton
  },
  data () {
    return {
      scrolled: false,
      styles
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      this.scrolled = window.scrollY > 32
    },
    scrollToContact () {
      var element = document.getElementById('contact');
      var top = element.offsetTop;
      window.scrollTo(0, top - 100);
    }
  }
}
</script>
