import Vue from 'vue'
import App from './App'

import axios from 'axios'
import { router } from './router'

var VueScrollTo = require('vue-scrollto')

import '@/assets/css/tailwind.css'

Vue.use(VueScrollTo)

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:5000'
} else {
  axios.defaults.baseURL = 'https://www.grnt.fr'
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
