<template lang="pug">
  div(:class="`${styles.block} relative`")
    //- | -right-96 md_-right-72 lg_-right-72 xl_right-0
    div.absolute.top-0.right-0.overflow-hidden(
      :class="'w-36 md_w-96'"
      style="height: 810px"
    )
      .relative
        svg.absolute.left-0.top-0(
          width="520"
          height="810"
          viewBox="0 0 520 810"
          fill="none"
        )
          path(
            d="M609.037 13.065C669.085 38.1457 706.615 108.371 747.362 176.591C787.036 244.81 831 310.02 815.988 367.204C799.904 424.388 726.988 473.546 679.808 530.73C633.699 588.917 614.398 656.133 569.362 712.313C523.254 768.494 452.483 814.642 383.856 809.626C316.302 803.607 250.892 746.423 179.049 704.288C107.206 662.152 28.9289 635.065 7.48316 582.897C-13.9626 530.73 22.4952 452.478 25.7121 377.236C28.9289 300.991 -1.09515 226.752 10.7 160.539C23.5675 93.3231 79.3264 34.1328 147.953 14.0683C216.579 -6.99948 298.073 11.0586 381.712 10.0553C464.278 8.04889 547.916 -12.0156 609.037 13.065Z"
            fill="#F0F3FC"
          )
    .relative(:class="`${styles.mainContainer}`")
      p(:class="styles.blockTitle") Témoignages
        span.text-blue &nbspclients
      .flex.flex-row.justify-center.mt-20.relative
        .w-full.flex.items-center
          transition(name="fade" mode="in-out")
            .flex.flex-row.items-center.justify-center.flex-grow.mb-6(v-if="showTestimonials")
              .flex-1.bg-white.rounded-lg.py-10.px-11.sm-px-8.custom-shadow.max-w-sm.lg_max-w-md.mx-4(
                v-for="(testimonialIndex, index) of indexes"
                :key="index"
                :class="{'md_mr-4': index === 0, 'md_ml-4': index === 1, 'hidden lg_block' : index === 1}"
              )
                .flex.flex-row.items-start.mb-6
                  .w-16.h-16.rounded-full.bg-grey.mr-6.bg-cover(:style="`background-image: url(${require(`@/assets/images/testimonials/${testimonials[testimonialIndex].picture}`)})`")
                  .flex-1
                    p.text-xl.md_text-2xl.font-sans-semibold.mb-2 {{testimonials[testimonialIndex].name}}
                    p.text-base.md_text-lg.font-sans-regular.text-grey {{testimonials[testimonialIndex].position}}
                div
                  p.font-serif-bold.text-5xl.text-blue.mb-4.h-2 “
                  p.text-base.md_text-lg.font-sans-regular.text-grey {{testimonials[testimonialIndex].message.fr}}
        .absolute.top-36.flex.w-full.justify-between
          CustomButton.self-center(
            arrow="left"
            :disabled="!showTestimonials"
            @onClick="previousTestimonial"
          )
          CustomButton.self-center(
            arrow="right"
            :disabled="!showTestimonials"
            @onClick="nextTestimonial"
          )
</template>

<script>
import styles from '@/assets/styles'

import CustomButton from '@/components/CustomButton'

export default {
  name: 'Testimonials',
  components: {
    CustomButton
  },
  data () {
    return {
      indexes: [0, 1],
      showTestimonials: true,
      styles,
      testimonials: [
        {
          name: 'Ankur Bansal',
          picture: 'ankur-bansal.jpg',
          position: 'Co-fondateur et directeur chez Hashtag You GmbH',
          message: {
            en: 'We were looking for a team which can work with us closely in translating our knowledge into a product. The team took ownership of all topics, was able to deliver features quite close to our vision from the first version itself, and has managed to identify technical solutions to quite complex feature needs.',
            fr: 'Nous étions à la recherche d\'une équipe capable de travailler main dans la main avec nous pour traduire nos connaissances en un produit. L’équipe s’est approprié tous les différents sujets, a développé des fonctionnalités très alignées avec notre vision dès la première version, et a su trouver des solutions techniques de qualité pour nos besoins complexes.'
          }
        },
        {
          name: 'Idriss Soumare',
          picture: 'idriss-soumare.jpg',
          position: 'Fondateur et CEO chez mktBI',
          message: {
            en: 'Granite helped us adopt a user-centric approach, clarify our objectives and break down a complex project into more manageable parts. They were particularly useful in challenging our features requests to simplify and shorten time to launch. Communication was fluid and they did a good job of describing technical options in simple terms for quicker decisions. Overall responsive, efficient and on budget.',
            fr: 'Granite nous a aidé à adopter une approche user-centric, à clarifier nos objectifs et découper un projet assez complexe en lots plus réalisables. Ils nous ont aidés à challenger notre expression de besoin pour simplifier les fonctionnalités et ainsi raccourcir les délais de production. La communication était fluide et ils ont su nous présenter les options techniques en termes compréhensibles pour nous aider à prendre des décisions. En résumé : ils ont été réactifs et efficaces tout en respectant le budget prévu.'
          }
        },
        {
          name: 'Othmane Zrikem',
          picture: 'othmane-zrikem.jpg',
          position: 'Chief Data Officer chez A/O PropTech',
          message: {
            en: 'We collaborated on the creation of a data integration product aggregating several in-house datasources (marketing, financial, CRM) and public APIs. I enjoyed the reactivness and the expertise of both co-founders who were very quick at delivering a fully fonctionning product with accurate documentation and data workflow analysis.',
            fr: 'Nous avons collaboré sur la création d\'un outil permettant d\'agréger de multiples sources de données internes (marketing, finances, CRM) et des APIs publiques. J’ai particulièrement apprécié la réactivité et l’expertise de l’équipe, qui a réussi à délivrer très vite un produit fonctionnel avec une documentation précise.'
          }
        },
        {
          name: 'Andrey Besedin',
          picture: 'andrey-besedin.jpg',
          position: 'Chief Science Officer, EyePick',
          message: {
            en: 'Granite helped us build data pipelines and infrastructure for our extremely data-centric application. They\'ve done a great job, their pricing was clear, communication - fluent, and all the deadlines were perfectly respected.',
            fr: 'Granite nous a aidé à construire des pipelines de données et l\'infrastructure de notre application centrée sur l\'usage de données. Ils ont fait un super boulot : leur pricing était clair, la communication fluide, et toutes les échéances ont été parfaitement respectées.'
          }
        },
        {
          name: 'Nathan Grass',
          picture: 'nathan-grass.png',
          position: 'Fondateur chez Pepperclip Studio',
          message: {
            en: 'We worked with Granite on the creation of a website that promotes social businesses. The team has always been able to find solutions, even for the most complex problems. Moreover everything has been done in a very joyful and positive approche.',
            fr: 'Nous avons travaillé avec Granite sur la création d\'un site internet qui met en avant des social business. Ils disposent d’une réelle capacité à écouter et comprendre les sujets. L’équipe a toujours réussi à trouver des solutions, y compris à des problématiques très complexes. Et le tout dans une ambiance de travail agréable et positive.'
          }
        },
        {
          name: 'Adrian Pellegrini',
          picture: 'adrian-pellegrini.png',
          position: 'Partner chez Blue DME',
          message: {
            en: 'The job was excellent. The level of technicity allows to have discussion more about concepts and not just on \'how to\'. Granite do not hesitate to listen our needs and propose solutions out of the box. It is rare for an agency, and very comfortable for us.',
            fr: 'Ils ont produit un excellent travail. Leur niveau de technicité leur permet de pouvoir échanger sur les principes et les concepts et pas seulement la manière de faire. Granite a su écouter nos besoins et nous proposer des solutions ingénieuses. C’est assez rare pour une agence, et ça a été très appréciable.'
          }
        },
        {
          name: 'Céline Coq',
          picture: 'celine-coq.jpg',
          position: 'Lead UX/UI Designer',
          message: {
            en: 'The team has an excellent problem-solving attitude and is able to iterate rapidly - this is crucial in prototype development. They are also extremely responsive and are able to complete complex development work quite quickly.',
            fr: 'L’équipe cherche en permanence des solutions et est capable d’itérer rapidement - ce qui est crucial lors d’une phase de prototypage. Ils sont très réactifs et savent réaliser des tâches de développement complexes très vite.'
          }
        }
      ]
    }
  },
  methods: {
    nextTestimonial () {
      this.showTestimonials = false
      let firstIndex = 0
      let secondIndex = 0
      if (this.indexes[0] + 2 > this.testimonials.length - 1) {
        firstIndex = this.indexes[0] + 2 - this.testimonials.length
      } else {
        firstIndex = this.indexes[0] + 2
      }
      if (firstIndex + 1 > this.testimonials.length - 1) {
        secondIndex = 0
      } else {
        secondIndex = firstIndex + 1
      }
      this.indexes = [firstIndex, secondIndex]
      setTimeout(() => {
        this.showTestimonials = true
      }, 500)
    },
    previousTestimonial () {
      this.showTestimonials = false
      let firstIndex = 0
      let secondIndex = 0
      if (this.indexes[0] - 2 < 0) {
        firstIndex = this.testimonials.length + (this.indexes[0] - 2)
      } else {
        firstIndex = this.indexes[0] - 2
      }
      if (firstIndex + 1 > this.testimonials.length - 1) {
        secondIndex = 0
      } else {
        secondIndex = firstIndex + 1
      }
      this.indexes = [firstIndex, secondIndex]
      setTimeout(() => {
        this.showTestimonials = true
      }, 500)
    }
  }
}
</script>
