<template lang="pug">
  div(:class="`${styles.mainContainer} ${styles.block} relative`")
    p(:class="styles.blockTitle") Les fondateurs
    .flex.flex-row.justify-center
      .flex.flex-row.items-center.justify-center.flex-wrap.pt-6
        .bg-white.rounded-lg.custom-shadow.w-64.mx-4.overflow-hidden.mb-6(
          v-for="(founder, index) of founders"
          :key="index"
        )
          img.w-full(:src="founder.picture")
          .px-4.py-8
            p.text-xl.md_text-2xl.font-sans-semibold.mb-2.text-center {{founder.name}}
            p.text-base.md_text-lg.font-sans-regular.text-grey.text-center.mb-4 {{founder.position}}
            a(
              :href="founder.linkedin_url"
              target="_blank"
            )
              img.w-6.m-auto(src="@/assets/images/logo-linkedin.svg")
</template>

<script>
import styles from '@/assets/styles'

export default {
  name: 'Founders',
  data () {
    return {
      founders: [
        {
          picture: require('@/assets/images/founders/ksenia-kanishcheva.png'),
          name: 'Ksenia Kanishcheva',
          position: 'CTO',
          linkedin_url: 'https://www.linkedin.com/in/ksenia-kanishcheva-304a455b'
        },
        {
          picture: require('@/assets/images/founders/julien-demoutiez.png'),
          name: 'Julien Demoutiez',
          position: 'CEO',
          linkedin_url: 'https://www.linkedin.com/in/julien-demoutiez-53a44620'
        }
      ],
      styles
    }
  }
}
</script>
