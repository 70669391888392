<template lang="pug">
  .w-full.bg-darkblue
    .h-60.w-full
      MglMap.w-full(
        :accessToken="accessToken"
        :center.sync="mapCenter"
        :mapStyle.sync="mapStyle",
        :zoom.sync="mapZoom"
        :attributionControl="false"
        :logoPosition="'bottom-right'"
        :interactive="false"
      )
        MglMarker(:coordinates.sync="marker")
          img(
            slot="marker"
            src="@/assets/images/pin.svg"
        )
    .max-w-5xl.mx-auto.pt-20.pb-8
      .flex.flex-wrap.mb-20.w-full
        .w-full.md_flex-1.mb-16.md_mb-0
          img.h-4.m-auto(src="@/assets/images/logo-light.svg")
        .md_flex-1.text-center.w-full.mb-4.sm_mb-0(class="sm_w-1/3")
          .text-base.text-white.font-sans-regular 15 bis, avenue Jean Aicard
          .text-base.text-white.font-sans-regular 75011 Paris
        .md_flex-1.text-center.w-full.mb-6.sm_mb-0(:class="'sm_w-1/3'")
          a(href="mailto:hello@grnt.fr")
            span.text-base.text-white.font-sans-regular hello@grnt.fr
        .md_flex-1.w-full(:class="'sm_w-1/3'")
          a(
            href="https://www.linkedin.com/company/grnt"
            target="_blank"
          )
            img.h-6.m-auto(src="@/assets/images/logo-linkedin.svg")
      .text-center.w-full.text-white.text-xs.font-sans-regular
        | © Granite {{new Date().getFullYear()}}. All rights reserved
</template>

<script>
import Mapbox from "mapbox-gl";
import { MglMap,  MglMarker} from "vue-mapbox";

export default {
  name: 'Footer',
  data () {
    return {
      accessToken: undefined,
      mapCenter: [2.367539260615503, 48.865755979428097],
      mapStyle: 'mapbox://styles/juliendemout/cjxug3ej03e831cqeql2beo58',
      mapZoom: 12.48,
      marker: [2.380724, 48.866740]
    }
  },
  components: {
    MglMap,
    MglMarker
  },
  created () {
    this.accessToken = process.env.VUE_APP_MAPBOX_TOKEN
    this.mapbox = Mapbox
  }
}
</script>
